<template>
 <div class="movie">
     <img class="movie-banner" src="@/assets/images/movieBeig.png"/>
     <!--<img class="movie-banner" src="@/assets/images/movieBg2.png"/>
     <img class="movie-banner" src="@/assets/images/movieBg3.png"/>
     <img class="movie-banner" src="@/assets/images/movieBg4.png"/>
     <img class="movie-banner" src="@/assets/images/movieBg5.png"/>
     <img class="movie-banner" src="@/assets/images/movieBg6.png"/>
     <img class="movie-banner" src="@/assets/images/movieBg7.png"/>
     <img class="movie-banner" src="@/assets/images/movieBg8.png"/>
     <img class="movie-banner" src="@/assets/images/movieBg9.png"/>-->
 </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
    
     
    });

    // 页面加载时调用
    onMounted(() => {});
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style lang='scss' scoped>
.movie{
    width: 100%;
    background: #000000;
   img{
     margin: 0;
     padding: 0;
     margin-bottom: 50px;
     width: 100%;
    //  &:nth-of-type(1){

    //  }
   }
}
</style>